import { webUtils } from './web-utils.js';

// Navbar Setup
function setupNavbar() {
    if (!webUtils.checkIsAuthCookie()) {
        // sessionStorage.removeItem('userResult');
        $('#nav-logged-in').addClass('hidden');
        $('#nav-logged-out').removeClass('hidden');
        $('body').removeClass('sunbasket-logged-in');
        $('body').addClass('sunbasket-logged-out');
        $('body').addClass('ready');
    } else {
        var ajaxurl = '/cms/api/user';
        $.ajax({
            url: webUtils.getAjaxUrl(ajaxurl),
            type: 'GET',
            cache: true,
            xhrFields: { withCredentials: true },
            success: function (result) {
                setupLoggedInNavItems(result);
                $('body').addClass('ready');

                if (window.analytics) {
                    window.analytics.identify(args.id);
                }

                $('.navbar-minimal a.sign-in').text('Sign Out').addClass('logout-link').removeClass('sign-in');
            },
            error: function (e) {
                $('#nav-logged-out').removeClass('hidden');
                $('#nav-logged-in').addClass('hidden');
                $('body').removeClass('sunbasket-logged-in');
                $('body').addClass('sunbasket-logged-out');
                $('body').addClass('ready');
                webUtils.deleteCookie('is_authenticated');
                console.error(e);
            }
        });
    }
}

// local testing of this can be adjusted via the return_user_json() function in functions.php

function setupLoggedInNavItems(args) {
    $('#nav-logged-out').not('.nav-join').addClass('hidden');
    $('body').addClass('sunbasket-logged-in');
    $('body').removeClass('sunbasket-logged-out');
    webUtils.deleteCookie('offer');

    var $nav = $('#nav-logged-in');

    args.firstName ? $('.firstName').html(args.firstName + ' <span class="caret"></span>') : null;

    if ((args.status == 'PENDING') && !args.signUpComplete) {
        $('body').addClass('sign-up-incomplete');
        $('.unhide-complete-signup').removeClass('hidden');
        $('.hide-complete-signup').addClass('hidden');
        $('a.hide-complete-signup').parent().addClass('hidden');
        $('#nav-logged-out').removeClass('hidden');
        $('body').removeClass('sunbasket-logged-in');
        $('body').addClass('sunbasket-logged-out');
    } else {
        (args.isActive || (args.status == 'ACTIVE')) ? $nav.find('.isActive').removeClass('hidden') : null;
        args.mealsPerWeek ? $nav.find('.mealsPerWeek').text(args.mealsPerWeek) : '';
        args.numberOfPeople ? $nav.find('.numberOfPeople').text(args.numberOfPeople) : '';
        args.mealPlan ? $nav.find('.mealplan').text(args.mealPlan) : '';
        args.deliveryDay ? $nav.find('.deliveryDay').text(args.deliveryDay.toLowerCase()) : '';
        args.activeMember ? $nav.find('.isActiveMember').removeClass('hidden') : null;
        (!args.activeMember && args.eligibleForMembership) ? $nav.find('.eligibleForMembership.notActiveMember').removeClass('hidden') : null;
        (args.activeMember || !args.eligibleForMembership) ? $nav.find('.eligibleForMembership.notActiveMember').addClass('hidden') : null;
        args.isAdmin ? $('.isAdmin').removeClass('hidden') : null;
        (args.vip && args.csTier == 'CHEF_TABLE' && !args.activeMember) ? $nav.find('.badges .vip-badge').removeClass('hidden') : null;
        (args.vip && args.csTier == 'CHEF_TABLE') ? $nav.find('.csTier-vip').removeClass('hidden') : null;
        args.showReferAFriend ? $nav.find('.referralCode').removeClass('hidden') : null;
        args.in1A1B ? $nav.find('.in1A1B').removeClass('hidden') : null;
        args.in1A1B ? $nav.find('.referralCode').addClass('hidden') : null;
        args.showWineCta ? $nav.find('.showWineCta').removeClass('hidden') : null;
        args.showRecycle ? $nav.find('.recycle').removeClass('hidden') : null;
        setupCreditsNavItems(args);
        toggleLoggedInFooterLinks(args);
        $('#nav-logged-in').removeClass('hidden');
    }

    if (args.impersonating) {
        $('body').addClass('sunbasket-user-impersonating');

        var membership = args.eligibleForMembership ? (args.activeMember ? "Active" : "Not Active") : "Not Eligible";
        var address = args.address.street + ", " + args.address.city + ", " + args.address.state + "&nbsp;" + args.address.postalCode;

        var cohortsList = $('<ul/>');
        args.cohorts.forEach(function (cohort) {
            $('<li/>').text(cohort).appendTo(cohortsList);
        });

        var $impersonationBanner = $([
            "<div id='impersonation-top-banner'>",
            "  <div class='container'>",
            "    <div class='row'>",
            "      <div class='col-md-12'>",
            "        <div class='banner-body'>",
            "          <div class='row'>",

            "            <div class='col-md-5'>",
            "              <p class='headline'>You are impersonating the account of:</p>",
            "              <p>Name: <strong>" + args.fullName + "</strong></p>",
            "              <p>Email: <strong>" + args.email + "</strong></p>",
            "              <p>Phone: <strong>" + args.phone + "</strong></p>",
            "              <p>Membership: <strong>" + membership + "</strong></p>",
            "              <p>CS - User Tier: <strong>" + args.csTier + "</strong></p>",
            "              <p>Address: <strong>" + address + "</strong></p>",
            "            </div>",

            "            <div class='col-md-5'>",
            "              <p><strong>User Cohorts:</strong></p>",
            "              <ul class='cohorts-list'>" + cohortsList.html() + "</ul>",
            "            </div>",

            "            <div class='col-md-2 text-center'>",
            "              <p>&nbsp;</p>",
            "              <a href='/admin/impersonate_exit?impersonatedUserId=" + args.id + "'>",
            "                <button type='submit' class='btn btn-sm btn-primary'>Exit Impersonation</button>",
            "              </a>",
            "            </div>",

            "          </div>",
            "        </div>",
            "      </div>",
            "    </div>",
            "  </div>",
            "</div>"
        ].join("\n"));

        $('header').prepend($impersonationBanner);
    }
}

function setupCreditsNavItems(args) {
    // Credits
    //show badge if credits or coupons
    (args.hasAnyCredits || args.hasCoupons) ? $('.showCreditsCouponsBadge').removeClass('hidden') : null;

    //config credit text
    (args.hasAnyCredits) ? $('.showCreditsBadge').removeClass('hidden') : null;

    (args.hasAnyCredits && !args.hasCoupons && !args.giftCredit) ? $('.justHasCredits').removeClass('hidden') : null;

    args.hasAnyCredits ? $('.hasAnyCredits').removeClass('hidden') : null;
    args.totalCredit ? $('.totalCredit').text(args.totalCredit) : null;
    args.totalCredit ? $('.user-credit-widget-mobile, .totalCredit').find('.credit-amount').text(args.totalCredit) : null;
    args.totalCreditValue ? $('.totalCreditValue').removeClass('hidden') : null;

    //config coupon text
    (args.hasCoupons && !args.hasAnyCredits) ? $('.showCouponsBadge').removeClass('hidden') : null; // show Coupons Badge if both credits and coupons exist
    args.hasCoupons ? $('.hasCoupons').removeClass('hidden') : null;
    (args.hasCoupons && args.giftCredit) ? $('.showhasCoupons').removeClass('hidden') : null;
    args.giftCredit ? $('.giftCredit').number(args.giftCredit, 2) : null;
    args.hasGiftBalance ? $('.hasGiftBalance').removeClass('hidden') : null;

    //config offer text
    ((args.hasCoupons && args.hasAnyCredits) && (args.multiWeekPromo || args.singleWeekPromo)) ? $('.hasPromos').removeClass('hidden') : null;
    (args.multiWeekPromo && args.hasAnyCredits) ? $('.multiWeekPromoShow').removeClass('hidden') : null;
    (args.multiWeekPromo && args.hasAnyCredits) ? $('.multiWeekPromo').text('+' + args.multiWeekPromo) : null;
    (args.singleWeekPromo && args.hasAnyCredits) ? $('.singleWeekPromoShow').removeClass('hidden') : null;
    (args.singleWeekPromo && args.hasAnyCredits) ? $('.singleWeekPromo').text(args.singleWeekPromo) : null;

}

function toggleLoggedInFooterLinks(args) {
    $('footer .hide-when-logged-in').addClass('hidden');
    $('footer .show-when-logged-in').removeClass('hidden');
    args.showWineCta ? $('footer .wine-club').removeClass('hidden') : null;
}

function set_auth_cookie() {
    webUtils.setCookie('is_authenticated', 'true', 3);
}

function logoutUser() {
    if (!webUtils.hasWebsiteLocalHost()) {
        webUtils.deleteCookie('is_authenticated');
        window.location.href = "/";
    } else {
        var ajaxurl = '/cms/api/logout';
        $.ajax({
            url: ajaxurl,
            type: 'GET',
            xhrFields: { withCredentials: true },
            success: function (result) {
                window.location.href = "/";
            },
            error: function (error) {
                console.error(error);
                window.location.href = "/";
            }
        });
    }
}

$(document).ready(function () {
    if ($('body').hasClass('noHeaders')) {
        $('body .page-wrapper').css({
            'padding-top': 0,
            'margin-top': 0
        });
    } else {
        $(window).on('load', function () {
            setTimeout(function () {
                if (!$('body').hasClass('try')) {
                    //calculate height of the header after everything is loaded and styles are set
                    $('body .page-wrapper').css({
                        'padding-top': $('header').height() + 'px',
                        'margin-top': 0
                    });

                    $('body header').css('position', 'fixed');

                    $(window).resize(function () {
                        $('body .page-wrapper').css('padding-top', $('header').height() + 'px');
                    });
                }
            }, 1000);
        });

    };

    $('body').on('click', '.logout-link', function (e) {
        e.preventDefault();
        logoutUser();
    });

    if (!webUtils.hasWebsiteLocalHost()) {
        $('body:not(.minimal) .navbar-nav .sign-in').click(function (e) {
            e.preventDefault();
            set_auth_cookie();
            location.reload();
        });
    }

    setupNavbar();

    // when opening mobile menu, add overlay to background.
    $('.navbar-toggle').click(function () {
        if ($('.navbar-toggle').hasClass('collapsed')) {
            $('#mobile-page-cover').addClass('covered');
            $('.page-wrapper').addClass('limit-page-height');
        }
        else {
            $('#mobile-page-cover').removeClass('covered');
            $('.page-wrapper').removeClass('limit-page-height');
        }
    });

    // clicking outside of menu closes the menu
    $('#mobile-page-cover').click(function () {
        $('.navbar-toggle').click();
    });

    if ($('#collapsed-nav-logged-out').hasClass('in')) {
        $('.page-wrapper').on('click', function (e) {
            $('[data-toggle="collapse"]').each(function () {
                $('.collapse').collapse('hide');
            });
        });
    }

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 1) {
            var offerMessage = $('.banner .offer-message');
            $('#nav-logged-out').addClass('nav-shadow');
            if (offerMessage.length) {
                $('.btn-join').addClass('btn-join-scrolled');

            } else {
                $('.btn-join').addClass('btn-join-scrolled');
            }
            $('.primary-menu-cta-container-sm').addClass('scroll');
            $('header').removeClass('not-scrolled');

        } else {
            $('header').addClass('not-scrolled');
            $('#nav-logged-out').removeClass('nav-shadow');
            $('.btn-join').removeClass('btn-join-scrolled');
            $('.primary-menu-cta-container-sm').removeClass('scroll');
        }
    });

    if (webUtils.hasCookie('variablePricing')) {
        var variablePricingCookie = webUtils.getCookie('variablePricing');

        if (variablePricingCookie === 'control') {
            $('body').addClass('variable-pricing-control');
        } else if (variablePricingCookie && variablePricingCookie !== 'false') {
            $('body').addClass('variable-pricing-test');
        }
    }

    webUtils.smoothScroll($("body.minimal .navbar .navbar-nav:not(.account-links) li a"));
    webUtils.smoothScroll($("body .roundup-toc li a, .back-to-toc a"));
});
